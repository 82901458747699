import {Controller} from '@hotwired/stimulus';
import toastr from "toastr";

export default class extends Controller {
    static values = {text: String}


    copy() {
        navigator.clipboard.writeText(this.textValue)
        toastr.info('Link copied to clipboard')
    }
}